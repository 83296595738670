import React, { ReactNode } from 'react'
import { Text, TextProps } from '@chakra-ui/react';

type Props = TextProps & {
  children: ReactNode,
}

const CutText = ({ children, ...props }: Props) => {
  return (
    <Text as="div" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" {...props}>{children}</Text>
  )
}

export default CutText;
