import { useToast } from '@chakra-ui/react';
import { DEFAULT_ERROR_MESSAGE } from 'shared/utils/constants';

const useToastes = (
  errorMessage = DEFAULT_ERROR_MESSAGE
) => {
  const toast = useToast()

  return {
    successToast: (message: string = errorMessage) => toast({
      position: 'top-right',
      title: message,
      status: 'success',
      isClosable: true,
    }),
    errorToast: (message: string = errorMessage) => toast({
      position: 'top-right',
      title: message,
      status: 'error',
      isClosable: true,
    }),
    infoToast: (message: string = errorMessage) => toast({
      position: 'top-right',
      title: message,
      status: 'info',
      isClosable: true,
    }),
    warningToast: (message: string = errorMessage) => toast({
      position: 'top-right',
      title: message,
      status: 'warning',
      isClosable: true,
    }),
  }
};

export default useToastes;
