import { InfiniteData } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react'
import { ListParamsE, OrderT } from 'shared/api/types/common';
import { QueryOptionsT, InfiniteQueryResult, ListQueryRequestType } from 'shared/api/types/typedQueries';
import { useDebounce } from 'shared/hooks/useDebounce';
import useStateFromQueryParams from 'shared/hooks/useStateFromQueryParams';
import { FilterField } from 'shared/types/filters';
import { DEFAULT_PAGE_SIZE } from 'shared/utils/constants';
import { formatObjectWithDateAsStringsToNumbers } from 'shared/utils/devUtils';

interface Props<QueryData extends ListQueryRequestType> {
  filterFields?: FilterField[];
  query: (
    params?: Pick<QueryData, 'pathParams' | 'params'>,
    options?: QueryOptionsT
  ) => InfiniteQueryResult<InfiniteData<QueryData['response']>>;
}

const useGetSortingMethods = <QueryData extends ListQueryRequestType>({ filterFields, query }: Props<QueryData>) => {
  type TData = ArrayElement<QueryData['response']['data']>;
  type QueryParamsT = QueryData['params'];
  const { getSearchParams, unsetSearchParams, setSearchParam, unsetSearchParam } = useStateFromQueryParams();

  const {
    page: pageParam = 0,
    size: sizeParam = DEFAULT_PAGE_SIZE,
    order: orderParam = null,
    search: searchParam = '',
    sortBy: sortByParam = null,
    ...searchParams
  } = useMemo(() => getSearchParams<QueryData['params']>(), [])

  const filterDefaultValues = useMemo(
    () => {
      const defaultFieldsData = filterFields.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.defaultValue, }), {});
      return Object.assign(
        defaultFieldsData,
        formatObjectWithDateAsStringsToNumbers(searchParams)
      );
    },
    [filterFields]
  ) as QueryParamsT;

  const [filters, setFilters] = useState<QueryParamsT | null>(filterDefaultValues);
  const [size, setSize] = useState(+sizeParam);
  const [search, setSearch] = useState<string>(searchParam);
  const debouncedSearch = useDebounce<string>(search, 1000);
  const [page, setPage] = useState(+pageParam);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState<OrderT>(orderParam);
  const [sortBy, setSortBy] = useState<keyof TData>(sortByParam);

  const { data: queryData, isLoading } = query({
    params: {
      size,
      page,
      order,
      sortBy: sortBy as string,
      search: debouncedSearch,
      filter: filters,
    },
  });

  const data = useMemo(() => queryData ? ((queryData).pages.flatMap((page) => page.data) as TData[]) : [], [queryData])

  useEffect(() => {
    if (total && queryData?.pages[0].total === total) return;
    setTotal(queryData?.pages[0].total ?? 0)
  }, [queryData?.pages[0]]);

  const handleFilter = (values: QueryParamsT) => {
    setFilters(values);
    setPage(0);
    setSearchParam(ListParamsE.page, '0');
  }

  const changeSearch = (value: string) => {
    setSearch(value);
    setSearchParam(ListParamsE.search, value);
  };

  const clearFilter = () => {
    unsetSearchParams();
    setFilters({});
    setPage(0);
    setSearch('');
  };

  const changeSize = (value: number) => {
    setSize(value);
    setSearchParam(ListParamsE.size, value.toString());
  }

  const changePage = (value: number) => {
    setPage(value);
    setSearchParam(ListParamsE.page, value.toString());
  }

  const changeOrder = (value: OrderT | null) => {
    setOrder(value);
    if (!value) {
      unsetSearchParam(ListParamsE.order);
    } else {
      setSearchParam(ListParamsE.order, value);
    }
  }

  const changeSortBy = (value: keyof TData | null) => {
    setSortBy(value);
    if (!value) {
      unsetSearchParam(ListParamsE.sortBy);
    } else {
      setSearchParam(ListParamsE.sortBy, value.toString());
    }
  }

  return {
    data,
    filters,
    isLoading,
    order,
    page,
    size,
    sortBy,
    total,
    changeOrder,
    changePage,
    search,
    changeSearch,
    changeSize,
    changeSortBy,
    clearFilter,
    handleFilter,
  }
}

export default useGetSortingMethods;
