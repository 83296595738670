import { Path, useController } from "react-hook-form";

const useIsInvalid = (name: Path<UnknownRecord>) => {
  const {
    formState: { errors, touchedFields },
  } = useController({ name });
  return !!errors[name] && !!touchedFields[name];
};

export default useIsInvalid;
