// Chakra imports
import { Box, Portal, useDisclosure, Container as ChakraContainer } from '@chakra-ui/react';
import Footer from 'shared/components/horizon-ui/footer/FooterAdmin';
// Layout components
import { useRef, useState } from 'react';
import Navbar from 'shared/components/horizon-ui/navbar/NavbarAdmin';
import Sidebar from 'shared/components/horizon-ui/sidebar/Sidebar';
import { SidebarContext } from 'shared/contexts/SidebarContext';
import useGetAllRoutes from 'shared/hooks/useGetAllRoutes';
import { LayoutsE, RouteT } from 'shared/routes';
import FooterAuth from 'shared/components/horizon-ui/footer/FooterAuth';
import { Outlet, useLocation } from 'react-router-dom';

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
	const [fixed] = useState(false);
	const [toggleSidebar, setToggleSidebar] = useState(false);
	const routes = useGetAllRoutes(true);
	const navbarRoutes = useGetAllRoutes();
	const location = useLocation();
	const containerRef = useRef();

	const getActiveRoute = (routes: RouteT[]): string => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (location.pathname === routes[i].layout + routes[i].path) {
				return routes[i].name + '123';
			}
		}
		return activeRoute + 'getActiveRoute';
	};
	const getActiveNavbar = (routes: RouteT[]): boolean => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
				return routes[i].secondary;
			}
		}
		return activeNavbar;
	};
	const getActiveNavbarText = (routes: RouteT[]): string | boolean => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
				return routes[i].name + '123';
			}
		}
		return activeNavbar + 'getActiveNavbarText';
	};

	const { onOpen } = useDisclosure();
	return (
		<Box>
			<SidebarContext.Provider
				value={{
					toggleSidebar,
					setToggleSidebar
				}}>
				<Sidebar routes={routes} display='none' {...props} />
				<Box
					float='right'
					minHeight='100vh'
					height='100%'
					overflow='auto'
					position='relative'
					maxHeight='100%'
					w={{ base: '100%', xl: 'calc( 100% - 260px )' }}
					maxWidth={{ base: '100%', xl: 'calc( 100% - 260px )' }}
					transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
					transitionDuration='.2s, .2s, .35s'
					transitionProperty='top, bottom, width'
					transitionTimingFunction='linear, linear, ease'>
					<Portal containerRef={containerRef}>
						<Box>
							<Navbar
								onOpen={onOpen}
								logoText={'Horizon UI Dashboard PRO'}
								brandText={getActiveRoute(navbarRoutes)}
								secondary={getActiveNavbar(navbarRoutes)}
								message={getActiveNavbarText(navbarRoutes)}
								fixed={fixed}
								{...props}
							/>
						</Box>
					</Portal>

					<ChakraContainer maxW="1400px" marginX="unset" overflowX='hidden' position="relative" p={{ base: '20px' }} pe='20px' minH='calc(100vh - 34px)' ref={containerRef}>
						<Outlet />
					</ChakraContainer>
					<ChakraContainer maxW="1400px" marginX="unset">
						<FooterAuth isHideLogo />
					</ChakraContainer>
				</Box>
			</SidebarContext.Provider>
		</Box>
	);
}
