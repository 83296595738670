import { Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLogin } from 'shared/api/requests/auth';
import PasswordField from 'shared/components/form/PasswordField';
import TextField from 'shared/components/form/TextField';
import Link from 'shared/components/Link';
import ShadowCard from 'shared/components/ShadowCard';
import SubmitButton from 'shared/components/SubmitButton';
import { LoginFormT, loginResolver, loginValues } from 'shared/form-values/auth';
import useGetColors from 'shared/hooks/useGetColors';
import useTypedHistory from 'shared/hooks/useTypedHistory';
import { LayoutsE, PathsE } from 'shared/routes';
import { fillFormErrors, getCookies } from 'shared/utils/devUtils';

const Login = () => {
  const otpCodeExpirationTimeFromCookie = getCookies('otpCodeExpirationTime');
  const otpCodeExpirationTime = otpCodeExpirationTimeFromCookie - new Date().getTime();
  const push = useTypedHistory();
  const { mutate, isPending } = useLogin();
  const methods = useForm<LoginFormT>({
    defaultValues: loginValues,
    resolver: loginResolver,
    mode: "onChange"
  });
  useEffect(() => {
    methods.trigger();
  }, []);
  const { textColorBrand } = useGetColors();
  const onSubmit: SubmitHandler<LoginFormT> = (values) => mutate(values, { onError: (error) => fillFormErrors<LoginFormT>(methods.setError, error, methods.getValues()) });

  useEffect(() => {
    if (otpCodeExpirationTime > 0) push(PathsE.TWO_FA, LayoutsE.AUTH);
  }, [otpCodeExpirationTime])

  return (
    <ShadowCard titleText="Sign In">
      <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TextField<LoginFormT>
          name="identifier"
          type="email"
          label="Email"
          placeholder="Enter your email"
          mb="24px"
        />
        <PasswordField<LoginFormT> />
        <SubmitButton label="Next" mt="40px" mb="24px" isLoading={isPending} />
        <Link layout={LayoutsE.AUTH} path={PathsE.FORGOT_PASSWORD}>
          <Text
            color={textColorBrand}
            fontSize="md"
            fontWeight="500"
            textAlign="center"
          >
            Forgot password?
          </Text>
        </Link>
      </form>
      </FormProvider>
    </ShadowCard>
  );
};

export default Login;
