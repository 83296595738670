import React from 'react';
import { Tr, Th, Tfoot } from '@chakra-ui/react';
import { Table, flexRender } from '@tanstack/react-table';

interface Props<TData extends UnknownRecord> {
  table: Table<TData>;
}

const TableFooter = <TData extends UnknownRecord>({
  table,
}: Props<TData>) => {
  return (
    <Tfoot>
      {table.getFooterGroups().map((footerGroup) => (
        <Tr key={footerGroup.id}>
          {footerGroup.headers.map((header) => (
            <Th key={header.id} {...header.column.columnDef?.meta?.styles?.sizes}>
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.footer,
                    header.getContext()
                  )}
            </Th>
          ))}
        </Tr>
      ))}
    </Tfoot>
  );
};

export default TableFooter;
