import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver } from 'react-hook-form';
import { CreateBotT, OneBotT, UpdateBotT, BotTypeT } from 'shared/api/types/bots';
import * as Yup from 'yup';
import initDefaultSchema from '../yupConfig';

initDefaultSchema();

const botTypes: BotTypeT[] = ['linkGenerate', 'inviteApprove', 'single'];

export const botTypeOptions = botTypes.map((type) => ({
  value: type,
  label: type,
}))

// Create Bot
export type CreateBotFormT = CreateBotT['request'];
export const getCreateBotValues = (): CreateBotFormT => ({
  type: null,
  name: '',
  username: '',
  token: '',
});
const createBotSchema: Yup.ObjectSchema<CreateBotFormT> = Yup.object({
  type: Yup.string().oneOf(botTypes).required(),
  name: Yup.string().required(),
  token: Yup.string().required(),
  username: Yup.string().required(),
});
export const createBotResolver = yupResolver(createBotSchema) as Resolver<CreateBotFormT>;

// Update Bot
export type UpdateBotFormT = UpdateBotT['request'];
export const getUpdateBotValues = (values?: OneBotT['response']): UpdateBotFormT => ({
  type: values?.type ?? null,
  name: values?.name ?? '',
  username: values?.username ?? '',
});
const updateBotSchema: Yup.ObjectSchema<UpdateBotFormT> = Yup.object({
  type: Yup.string().oneOf(botTypes).required(),
  name: Yup.string().required(),
  username: Yup.string().required(),
});
export const updateBotResolver = yupResolver(updateBotSchema) as Resolver<UpdateBotFormT>;
