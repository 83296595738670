import React, { memo, useMemo } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AuthContextProvider } from './contexts/AuthContext';
import { SettingsContextProvider } from './contexts/SettingsContext';
import useToastes from './hooks/useToastes';
import { FIVE_MINUTES_MILLISECONDS } from './utils/constants';
import { defaultErrorHandler } from './utils/devUtils';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Helmet } from 'react-helmet';

interface Props {
  children: React.ReactNode
}

const Container = ({ children }: Props) => {
  const { errorToast } = useToastes();

  const onError = defaultErrorHandler(errorToast);

  const queryClient = useMemo(() => (
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
          gcTime: FIVE_MINUTES_MILLISECONDS,
          staleTime: FIVE_MINUTES_MILLISECONDS,
        },
        mutations: {
          retry: false,
          onError,
        },
      },
      queryCache: new QueryCache({
        onError,
      }),
    })
  ), [])

  return (
    <SettingsContextProvider>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>{children}</AuthContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </SettingsContextProvider>
  );
};

export default memo(Container);
