import { Button, Center, HTMLChakraProps } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import useGetColors from 'shared/hooks/useGetColors';

type Props = Modify<
  HTMLChakraProps<'button'>,
  {
    label: string;
    isLoading?: boolean;
  }
>;

const SubmitButton = <F extends UnknownRecord>({ label, type = 'submit', isLoading, ...props }: Props) => {
  const { themeAccentColor } = useGetColors();
  const { formState: { isValid } } = useFormContext<F>()
  return (
    <Center>
      <Button
        {...props}
        type={type}
        fontSize="md"
        fontWeight="500"
        colorScheme={themeAccentColor}
        paddingX="80px"
        isLoading={isLoading}
        isDisabled={!isValid}
      >
        {label}
      </Button>
    </Center>
  )
};

export default SubmitButton;
