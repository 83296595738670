import { useContext, useMemo } from 'react';
import { useAuthContext } from 'shared/contexts/AuthContext';
import SettingsContext from '../contexts/SettingsContext';
import useRoutes, { LayoutsE } from '../routes';

const useGetAllRoutes = (isShowInNav?: boolean) => {
  const { isDevMode } = useContext(SettingsContext);
  const { self } = useAuthContext();
  const routes = useRoutes();

  return useMemo(
    () => {
      const newRoutes = (Object.entries(routes)
        .map(([layout, route]) => route.routes.map((item) => ({ ...item, layout: layout as LayoutsE, isProtected: route.isProtected, isHideInAuth: route.isHideInAuth, })))
        .flat()
        .filter((route) => (
          route.isProtected === !!self
          && route.isHideInAuth === !self
          && (isDevMode ? true : !route.isDisabled)
        )
        ))

      if (isShowInNav) return newRoutes.filter(item => item.isShowInNav)
      return newRoutes;
    },
    [isDevMode, self, routes],
  );
};

export default useGetAllRoutes;
