
export type OrderT = "DESC" | "ASC"

export enum ListParamsE {
  page = 'page',
  size = 'size',
  order = 'order',
  search = 'search',
  sortBy = 'sortBy',
}

export type GetListParamsT<TData extends UnknownRecord = UnknownRecord> = {
  [ListParamsE.page]?: number,
  [ListParamsE.size]?: number,
  [ListParamsE.order]?: OrderT,
  [ListParamsE.search]?: string,
  [ListParamsE.sortBy]?: keyof TData,
}

export type ModelFieldsT = {
  id: UUID,
  createdAt: string,
  updatedAt: string,
}

export type DeletedModelT = {
  isActive: boolean,
  isDeleted: boolean,
}

export type ListResT<Data extends UnknownRecord> = {
  page: number,
  size: number,
  total: number,
  data: Data[],
}
