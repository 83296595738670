import { Box, Heading } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/horizon-ui/card/Card';
import InfiniteTable from 'shared/components/table/InfiniteTable';
import useGetColors from 'shared/hooks/useGetColors';
import PageTitle from '../admin/PageTitle';
import { useGetColumns, useGetFilterFields, useGetGetBreadcrumbs } from './constants';
import { LinksT } from '../../api/types/links';
import { useGetLinks } from '../../api/requests/links';

const Index = () => {
  const { t } = useTranslation();
  const { blue800 } = useGetColors();
  const columns = useGetColumns();
  const breadcrumbs = useGetGetBreadcrumbs();
  const filterFields = useGetFilterFields();

  return (
    <Box>
      <PageTitle title={t('dtos.link_other')} breadcrumbs={breadcrumbs} />
      <Card mb="20px" borderRadius="12px">
        <Heading size="md" marginBottom={5} color={blue800}>
          {t('general.table.dtoTable', { dtoName: t('dtos.link_other') } )}
        </Heading>
        <InfiniteTable<LinksT>
          query={useGetLinks}
          columns={columns}
          filterFields={filterFields}
          clickable
        />
      </Card>
    </Box>
  );
};

export default Index;
