import { Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useActivateAccount, useSetPassword } from 'shared/api/requests/auth';
import PasswordField from 'shared/components/form/PasswordField';
import ShadowCard from 'shared/components/ShadowCard';
import SubmitButton from 'shared/components/SubmitButton';
import {
  SetPasswordFormT,
  setPasswordResolver,
  setPasswordValues
} from 'shared/form-values/auth';
import useGetColors from 'shared/hooks/useGetColors';
import useStateFromQueryParams from 'shared/hooks/useStateFromQueryParams';

const SetPassword = () => {
  const { getSearchParams } = useStateFromQueryParams();
  const { activationToken, resetPasswordToken } = getSearchParams<{
    activationToken?: string;
    resetPasswordToken?: string;
  }>();
  const { textColor } = useGetColors();

  const { mutate: activationMutate } = useActivateAccount({
    pathParams: { activationToken },
  });
  const { mutate: resetPasswordMutate } = useSetPassword({
    pathParams: { resetPasswordToken },
  });
  const mutate = resetPasswordToken ? resetPasswordMutate : activationMutate;

  const methods = useForm<SetPasswordFormT>({
    defaultValues: setPasswordValues,
    resolver: setPasswordResolver,
    mode: "onChange"
  });
  useEffect(() => {
    methods.trigger();
  }, []);
  const onSubmit: SubmitHandler<SetPasswordFormT> = ({ password }) => mutate({ password });

  return (
    <ShadowCard titleText="Set Password">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PasswordField<SetPasswordFormT>
            mb="24px"
          />
          <PasswordField<SetPasswordFormT>
            name="passwordConfirmation"
            label="Retype password"
          />
          <Text color={textColor} fontSize="sm" mt="20px">
            Password should contain 8-24 characters, uppercase, lowercase and
            digits
          </Text>
          <SubmitButton label="Set password" mt="40px" />
        </form>
      </FormProvider>
    </ShadowCard>
  );
};

export default SetPassword;
