import React, { useEffect, useMemo, useState } from 'react';
import { IS_LOCAL_ENV } from '../utils/constants';

export type Settings = {
  isDevMode: boolean,
}

const SettingsContext = React.createContext<Settings>({
  isDevMode: false,
});

interface Props {
  children: React.ReactNode;
}

export const SettingsContextProvider = ({ children }: Props) => {
  const [isDevMode, setIsDevMode] = useState(false);

  useEffect(() => setIsDevMode(IS_LOCAL_ENV), []);

  const enableDevMode = () => setIsDevMode(true);
  const disableDevMode = () => setIsDevMode(false);

  const value = useMemo(
    () => ({
      isDevMode,
      enableDevMode,
      disableDevMode,
    }),
    [isDevMode, enableDevMode, disableDevMode],
  );

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
