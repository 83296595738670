import { useNavigate } from "react-router-dom";
import { LayoutsE, PathsE } from "shared/routes";
import useGenerateUrl from "./useGenerateUrl";
import { useCallback } from "react";

const useTypedHistory = <PathParams extends Record<string, string> = undefined>() => {
  type OptionsT = {
    params?: UnknownRecord
  }
  const fillParams = (params?: OptionsT['params']) => params ? Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&') : '';
  const navigate = useNavigate();
  return useCallback((path: PathsE, layout: LayoutsE = LayoutsE.DASHBOARD, pathsParams?: PathParams extends undefined ? undefined : PathParams, options?: OptionsT) => {
    const pathname = useGenerateUrl(path, layout, pathsParams);
    return navigate({
      pathname,
      search: `?${fillParams(options?.params)}`,
    });
  }, []);
};

export default useTypedHistory;
