import { Box, Button, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetOneSubscriber, useUpdateOneSubscriber } from 'shared/api/requests/subscribers';
import Card from 'shared/components/horizon-ui/card/Card';
import { HSeparator } from 'shared/components/horizon-ui/separator/Separator';
import Link from 'shared/components/Link';
import Loader from 'shared/components/Loader';
import {
  getUpdateSubscriberValues,
  UpdateSubscriberFormT,
  updateSubscriberResolver
} from 'shared/form-values/subscribers';
import useGetColors from 'shared/hooks/useGetColors';
import { PathsE, PathsParams } from 'shared/routes';
import { fillFormErrors } from 'shared/utils/devUtils';
import PageTitle from '../admin/PageTitle';
import SubscriberForm from './SubscriberForm';

const Update = () => {
  const { t } = useTranslation();
  const { subscriberId } = useParams<PathsParams[PathsE.UPDATE_SUBSCRIBER]>();
  const { mutate, isPending: isLoadingUpdate } = useUpdateOneSubscriber({
    pathParams: { subscriberId },
    params: undefined,
  });
  const { data, isLoading } = useGetOneSubscriber({
    pathParams: { subscriberId },
    params: undefined,
  });

  const { gray800, gray700, white, gray100, brand500, brand400 } = useGetColors();
  const methods = useForm<UpdateSubscriberFormT>({
    defaultValues: getUpdateSubscriberValues(data),
    resolver: updateSubscriberResolver,
    mode: "onChange",
  });
  const { formState: { isValid }, trigger } = methods;

  useEffect(() => {
    trigger();
  }, []);
  
  useEffect(() => {
    methods.reset(data);
  }, [data])

  const onSubmit: SubmitHandler<UpdateSubscriberFormT> = (values) => {
    mutate(values, { onError: (error) => fillFormErrors(methods.setError, error) })
  };

  const breadcrumbs = [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
    {
      path: PathsE.SUBSCRIBERS,
      text: t('subscribers.subscribers'),
    },
    {
      path: PathsE.SHOW_SUBSCRIBER,
      text: t('subscribers.viewSubscriber'),
      pathsParams: {
        subscriberId: data?.id,
      },
    },
  ];

  if (isLoading) return <Loader />;

  return (
    <Box>
      <PageTitle
        title={t('subscribers.viewSubscriber')}
        breadcrumbs={breadcrumbs}
        backUrl={{
          path: PathsE.SHOW_SUBSCRIBER,
          pathsParams: {
            subscriberId: data?.id,
          }
        }}
      />
      <Card mb="20px" borderRadius="12px">
        <Text color={gray700} fontWeight="600">
          Personal Data
        </Text>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <SubscriberForm />
            <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
            <Link<PathsParams[PathsE.SHOW_SUBSCRIBER]> path={PathsE.SHOW_SUBSCRIBER} pathsParams={{ subscriberId }}>
              <Button
                color={gray800}
                bgColor={gray100}
                borderRadius="8px"
                w="100px"
              >
                Cancel
              </Button>
            </Link>
            <Button
              color={white}
              _hover={{
                bgColor: brand400,
              }}
              bgColor={brand500}
              borderRadius="8px"
              ml="20px"
              isLoading={isLoadingUpdate}
              w="100px"
              type="submit"
              isDisabled={!isValid}
            >
              Update
            </Button>
          </form>
        </FormProvider>
      </Card>
    </Box>
  );
};

export default Update;
