import TextField from 'shared/components/form/TextField';
import { HSeparator } from 'shared/components/horizon-ui/separator/Separator';
import { CreateSubscriberFormT } from 'shared/form-values/subscribers';

const AdminForm = () => {
  const fieldProps = {
    mb: '20px',
    w: {
      base: '100%',
      md: '50%',
    },
  };

  return (
    <>
      <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
      <TextField<CreateSubscriberFormT>
        name="identifier"
        type="email"
        label="Email *"
        placeholder="Enter your email"
        {...fieldProps}
      />
      <TextField<CreateSubscriberFormT>
        name="name"
        label="Name"
        placeholder="Enter name"
        {...fieldProps}
      />
      <TextField<CreateSubscriberFormT>
        name="username"
        label="Username"
        placeholder="Enter username"
        {...fieldProps}
      />
    </>
  );
};

export default AdminForm;
