import { useQueryClient } from '@tanstack/react-query';
import React, {
  createContext,
  useContext, useEffect, useMemo, useState
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { SELF_ADMIN_KEY, useGetAdminSelf } from 'shared/api/requests/subscribers';
// import { AdminSelfT } from 'shared/api/types/subscribers';
import { AUTHORIZATION_COOKIE, OTP_CODE_EXPIRATION_TIME_COOKIE, REFRESH_COOKIE, SESSION_ID } from 'shared/utils/constants';
import { useLogout } from '../api/requests/auth';
import { SELF_PERMISSIONS_KEY, useGetPermissionsSelf } from '../api/requests/permissions';
import { AuthTokensT } from '../api/types/auth';
import { PermissionsSelfT } from '../api/types/permissions';
import Loader from '../components/Loader';
import useToastes from '../hooks/useToastes';
import { LayoutsE, PathsE } from '../routes';
import { removeCookies, setCookies } from '../utils/devUtils';

type ContextType = {
  // self: AdminSelfT['response'] | null,
  self: null,
  permissions: PermissionsSelfT['response'],
  signIn: (data: AuthTokensT) => void,
  signOut: VoidFunction,
}

// const initialState: ContextType = {
//   self: null,
//   permissions: [],
//   signIn: () => null,
//   signOut: () => null,
// };

const initialState: ContextType = {
  self: {
    id: '165324424',
    createdAt: '123',
    updatedAt: '123',
    isActive: true,
    isDeleted: true,
    identifier: '@gjoiwegwj',
    firstName: 'string',
    lastName: 'string',
    middleName: 'string',
    createdById: 'string',
    contacts: [],
    roles: []
  } as FixMeLater,
  permissions: [],
  signIn: () => true,
  signOut: () => null,
};

const AuthContext = createContext<ContextType>(initialState);

interface Props {
  children: React.ReactNode;
}

export const AuthContextProvider = ({ children }: Props) => {
  const queryClient = useQueryClient();
  const [self, setSelf] = useState<ContextType['self']>(initialState.self);
  const [permissions, setPermissions] = useState<ContextType['permissions']>([]);
  const [targetLocation, setTargetLocation] = useState<ReturnType<typeof useLocation> | null>(null);
  // const { data: adminSelf, isSuccess: isSuccessAdminSelf, isFetching: isFetchingAdminSelf } = useGetAdminSelf();
  const { data: permissionsSelf, isSuccess: isSuccessPermissionsSelf, isFetching: isFetchingPermissionsSelf } = useGetPermissionsSelf();
  const useLogoutMutation = useLogout();
  const navigate = useNavigate();
  const location = useLocation();
  const { successToast } = useToastes();

  // const isFetching = useMemo(
  //   () => isFetchingPermissionsSelf && isFetchingAdminSelf,
  //   [isFetchingPermissionsSelf, isFetchingAdminSelf]
  // )

  // const isSuccess = useMemo(
  //   () => isSuccessPermissionsSelf && isSuccessAdminSelf,
  //   [isSuccessPermissionsSelf, isSuccessAdminSelf]
  // )

  // useEffect(() => {
  //   if (!adminSelf || !permissionsSelf) return;
  //   if (isSuccess) {
  //     setSelf(adminSelf);
  //     setPermissions(permissionsSelf);
  //     if (targetLocation && targetLocation.pathname !== '/' && !targetLocation.pathname.includes(LayoutsE.AUTH)) {
  //       navigate(targetLocation, { replace: true });
  //     } else {
  //       navigate(`/${LayoutsE.DASHBOARD}/${PathsE.SUBSCRIBERS}`, { replace: true });
  //     }
  //   }
  // }, [adminSelf, permissionsSelf, isSuccess, isFetching]);

  const signIn = ({ accessToken, refreshToken }: AuthTokensT) => {
    successToast('You have successfully logged in');
    setCookies(AUTHORIZATION_COOKIE, accessToken);
    setCookies(REFRESH_COOKIE, refreshToken);
    removeCookies(SESSION_ID);
    removeCookies(OTP_CODE_EXPIRATION_TIME_COOKIE);
    // navigate(location);
    queryClient.invalidateQueries({ queryKey: [SELF_PERMISSIONS_KEY] });
    // queryClient.invalidateQueries({ queryKey: [SELF_ADMIN_KEY] });
  };

  const signOut = () => {
    useLogoutMutation.mutate({}, {
      onSuccess: () => {
        setSelf(null);
        setTargetLocation(null);
        removeCookies(AUTHORIZATION_COOKIE);
        removeCookies(REFRESH_COOKIE);
        navigate(LayoutsE.AUTH, { replace: true });
      },
    });
  };

  useEffect(() => {
    if (targetLocation) return;
    setTargetLocation(location);
  }, []);

  const value = useMemo(
    () => ({
      self,
      permissions,
      signIn,
      signOut,
    }),
    [signIn, signOut, self, permissions],
  );

  return (
    <AuthContext.Provider value={value}>
      {/* {isFetching ? <Loader isAbsolute /> : children} */}
      {false ? <Loader isAbsolute /> : children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
