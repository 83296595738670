import { LayoutsE } from 'shared/routes';

// Chakra imports
import { Box, Center, Flex, useToken } from '@chakra-ui/react';

// Layout components
import Logo from 'components/Logo';
import Footer from 'shared/components/horizon-ui/footer/FooterAuth';
import { Outlet } from 'react-router-dom';

const SystemLayout = () => {
  const [authBg] = useToken(
    'colors',
    ['main']
  );

  return (
    <Flex
      bg={authBg}
      float="right"
      minHeight="100vh"
      height="100%"
      position="relative"
      w="100%"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Box mt="4%">
          <Logo />
        </Box>
        <Center height="100%" marginTop="10">
          {/* <Outlet /> */}
        </Center>
      </Box>
      <Box>
        <Footer />
      </Box>
    </Flex>
  );
};

export default SystemLayout;
