import React from 'react'
import { Heading } from '@chakra-ui/react';

interface Props {
  text: string,
}

const Title = ({ text }: Props) => {
  return (
    <Heading as='h3' size='md' fontWeight="bold" textAlign="center" mb="6">{text}</Heading>
  )
}

export default Title;
