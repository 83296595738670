/* eslint-disable */

import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import { Box, Center, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { LayoutsE, RouteT } from 'shared/routes';

export function SidebarLinks(props: {
	routes: RouteT[];
}) {
	//   Chakra color mode
	let location = useLocation();
	let activeColor = useColorModeValue('gray.900', 'white');
	let inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
	let activeIcon = useColorModeValue('brand.500', 'white');
	let textColor = useColorModeValue('secondaryGray.500', 'white');
	let brandColor = useColorModeValue('brand.500', 'brand.400');

	const { routes } = props;

	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName: string) => {
		return location.pathname.includes(routeName.toLowerCase());
	};

	// this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
	const createLinks = (
		routes: RouteT[],
	) => {
		return routes.map(
			(
				route: RouteT,
				index: number
			) => (
				<NavLink key={index} to={`/${route.layout}/${route.path}`}>
					{route.icon ? (
						<Box>
							<HStack
								// spacing={activeRoute(route.path) ? '22px' : '26px'}
								borderRadius="8px"
								boxShadow={activeRoute(route.path) ? "0px 1px 2px 0px rgba(0,0,0,0.06), 0px 1px 3px 0px rgba(0,0,0,0.1)" : "none"}
								bg={activeRoute(route.path) ? "white" : 'transparent'}
								p='8px'
							>
								<Flex w='100%' alignItems='center' justifyContent='center'>
									<Center
										color={activeRoute(route.path) ? activeIcon : textColor}
										me='16px'>
										{route.icon}
									</Center>
									<Text
										me='auto'
										color={activeRoute(route.path) ? activeColor : textColor}
										fontWeight="500"
									>
										{route.name}
									</Text>
								</Flex>
							</HStack>
						</Box>
					) : (
						<Box>
							<HStack
								// spacing={activeRoute(route.path) ? '22px' : '26px'}
								borderRadius="8px"
								boxShadow={activeRoute(route.path) ? "0px 1px 2px 0px rgba(0,0,0,0.06), 0px 1px 3px 0px rgba(0,0,0,0.1)" : "none"}
								bg={activeRoute(route.path) ? "white" : 'transparent'}
								p='8px'>
								<Text
									me='auto'
									color={activeRoute(route.path) ? activeColor : inactiveColor}
									fontWeight="500"
								>
									{route.name}
								</Text>
							</HStack>
						</Box>
					)}
				</NavLink>
			)
		);
	};
	//  BRAND
	return <>{createLinks(routes)}</>
}

export default SidebarLinks;
