import SelectField from 'shared/components/form/SelectField';
import TextField from 'shared/components/form/TextField';
import { HSeparator } from 'shared/components/horizon-ui/separator/Separator';
import { botTypeOptions, CreateBotFormT } from 'shared/form-values/bots';

interface Props {
  isCreateMode?: boolean,
}

const BotForm = ({ isCreateMode }: Props) => {
  const fieldProps = {
    mb: '20px',
    w: {
      base: '100%',
      md: '50%',
    },
  };

  return (
    <>
      <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
      <SelectField<CreateBotFormT>
        name="type"
        options={botTypeOptions}
        label="Type *"
        placeholder="Enter type"
        {...fieldProps}
      />
      {isCreateMode && (
        <TextField<CreateBotFormT>
          name="token"
          label="Token *"
          placeholder="Enter token"
          {...fieldProps}
        />
      )}
      <TextField<CreateBotFormT>
        name="name"
        label="Name *"
        placeholder="Enter name"
        {...fieldProps}
      />
      <TextField<CreateBotFormT>
        name="username"
        label="Username *"
        placeholder="Enter username"
        {...fieldProps}
      />
    </>
  );
};

export default BotForm;
