import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useStateFromQueryParams = <Model extends UnknownRecord>() => {
  type ResultModel = { [key in keyof Model]: string }
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const getStateData = useCallback((): ResultModel => (
    Array.from(searchParams)
      .reduce((prev, curr) => ({ ...prev, [curr[0]]: curr[1] }), {} as ResultModel)
  ), [location]);

  const unsetSearchParam = (name: string) => {
    searchParams.delete(name);
    saveSearchParams();
  };

  const unsetSearchParams = () => {
    navigate({
      pathname: location.pathname,
      search: '',
    })
  };

  const setSearchParam = (name: string, value: string) => {
    searchParams.set(name, value);
    saveSearchParams();
  };

  const getArrayFromQueryParams = (parameterName: string) => searchParams.getAll(`${parameterName}[]`);

  const setArrayInQueryParams = (parameterName: string, values: string[]) => {
    searchParams.delete(`${parameterName}[]`);
    values.forEach((value) => searchParams.append(`${parameterName}[]`, value));
    saveSearchParams();
  };

  const saveSearchParams = () => {
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  };

  const toUrlParams = (object: UnknownRecord): URLSearchParams => {
    const newSearchParams = new URLSearchParams();
    Object.entries(object)
      .forEach(([key, value]) => {
        if (Array.isArray(value)) {
          const values = value as Array<string | number>;
          newSearchParams.delete(`${key}[]`);
          values.forEach((item) => newSearchParams.append(`${key}[]`, item.toString()));
        } else if (value !== undefined && value !== null) {
          newSearchParams.set(key, value.toString());
        }
      });
    return newSearchParams;
  };

  const setSearchParams = (object: UnknownRecord) => {
    const combinedSearchParams = { ...getSearchParams(), ...object };
    navigate({
      pathname: location.pathname,
      search: toUrlParams(combinedSearchParams).toString(),
    })
  };

  const getSearchParams = <O extends UnknownRecord>() => {
    const object = Object.fromEntries(searchParams);
    const result = {} as O;
    Object.entries(object).forEach(([key, value]) => {
      type ObjKeyT = keyof O;
      if (key.includes('[]')) {
        const arrayName = key.replace(/\[\]/g, '') as ObjKeyT;
        result[arrayName] = getArrayFromQueryParams(arrayName as string) as O[ObjKeyT];
      } else {
        result[key as ObjKeyT] = value as O[ObjKeyT];
      }
    });
    return result;
  };

  return {
    setSearchParams,
    getSearchParams,
    unsetSearchParam,
    setSearchParam,
    getArrayFromQueryParams,
    setArrayInQueryParams,
    unsetSearchParams,
  };
};

export default useStateFromQueryParams;
