import axios, { AxiosRequestConfig, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import { BASE_URL } from '../../utils/constants';
import { getToken } from '../../utils/devUtils';

export const instance = axios.create({
  baseURL: `${BASE_URL}/api/v1`,
  withCredentials: false,
  headers: { 'Content-Type': 'application/json' },
});

export const removeEmptyValues = (obj: UnknownRecord): Record<string, unknown> => {
  if (!obj) return {};
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (typeof value === 'object' && value !== null) {
      const newValue = removeEmptyValues(value as UnknownRecord);
      if (Object.keys(newValue).length !== 0) {
        acc[key] = newValue;
      }
    } else if (value !== undefined && value !== null && value !== '') {
      acc[key] = value;
    }
    return acc;
  }, {} as UnknownRecord);
};

const setAuthToken = (config: InternalAxiosRequestConfig) => {
  const token = getToken();
  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      } as AxiosRequestHeaders,
    };
  }
  return config;
};

const formatDataToParams = (params: AxiosRequestConfig['params']): UnknownRecord => {
  if (!params) return params;
  return Object.entries(params).reduce((prev, [key, value]) => ({
    ...prev,
    [key]: Array.isArray(value)
      ? value.join(',')
      : typeof value === 'object' && value !== null
        ? formatDataToParams(value)
        : value,
  }), {});
}

instance.interceptors.request.use((config) => ({
  ...config,
  data: removeEmptyValues(config.data),
  url: config?.url?.replace(/[^=&]+=(?:&|$)/g, ''),
  params: removeEmptyValues(formatDataToParams(config.params)),
}));
instance.interceptors.request.use(setAuthToken);
instance.interceptors.response.use((response) => response.data.data);
