import { Box, Flex, Tag, TagLabel, Text } from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import MatIcon from "components/MatIcon";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetBots } from "shared/api/requests/bots";
import { useGetChannels } from "shared/api/requests/channels";
import { BotsElementT } from "shared/api/types/bots";
import CutText from "shared/components/CutText";
import MainItemMenu from "shared/components/MainItemMenu";
import { PathsE } from "shared/routes";
import { FilterField } from "shared/types/filters";
import { formatDateToHuman } from "shared/utils/formattings";

export const useGetFilterFields = (): FilterField[] => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      type: 'async-select',
      isMulti: true,
      name: 'channelIds',
      defaultValue: [],
      placeholder: 'Select channels',
      optionsQuery: useGetChannels as FixMeLater,
      label: t('fields.channel', { count: 0 }),
      size: "lg",
      labelName: 'name',
      valueName: 'id',
    },
    {
      type: 'async-select',
      isMulti: true,
      name: 'botIds',
      defaultValue: [],
      placeholder: 'Select bots',
      optionsQuery: useGetBots as FixMeLater,
      // TODO add translations
      label: 'Bots',
      size: "lg",
      labelName: 'name',
      valueName: 'id',
    },
    {
      type: 'date-range',
      name: 'messageAt',
      defaultValue: [],
      // TODO add translations
      label: 'Message at',
      size: "lg"
    },
    {
      type: 'date-range',
      name: 'createdAt',
      defaultValue: [],
      // TODO add translations
      label: 'Created at',
      size: "lg"
    },
  ], []);
}

export const useGetGetBreadcrumbs = () => {
  const { t } = useTranslation();
  return [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
  ];
}

interface Params {
  handleActivate(botId: string): void
  handleDeactivate(botId: string): void
}

export const useGetColumns = ({ handleActivate, handleDeactivate }: Params) => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<BotsElementT>();

  return useMemo(
    () => [
      columnHelper.accessor('id', {
        header: t('bots.table.id'),
        id: 'id',
        cell: ({ getValue }) => (
          <CutText fontWeight="700">
            {getValue()}
          </CutText>
        ),
        meta: {
          isSorting: false,
          styles: {
            sizes: {
              width: '90px',
              maxW: '90px',
            }
          }
        }
      }),
      columnHelper.accessor('type', {
        header: t('bots.table.type'),
        id: 'type',
      }),
      columnHelper.accessor('name', {
        header: t('bots.table.name'),
        id: 'name',
      }),
      columnHelper.accessor('username', {
        header: t('bots.table.username'),
        id: 'username',
      }),
      columnHelper.accessor('createdAt', {
        header: t('bots.table.createdAt'),
        id: 'createdAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '100px'
            }
          }
        }
      }),
      columnHelper.accessor('updatedAt', {
        header: t('bots.table.updatedAt'),
        id: 'updatedAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '100px'
            }
          }
        }
      }),
      columnHelper.accessor('id', {
        header: '',
        id: 'actions',
        cell: ({ row }) => (
          <MainItemMenu
            data={row.original}
            handleActivate={handleActivate}
            handleDeactivate={handleDeactivate}
            viewLink={PathsE.SHOW_BOT}
            pathsParams={{ botId: row.original.id }}
          />
        ),
        meta: {
          styles: {
            sizes: {
              width: '44px'
            }
          }
        }
      }),
    ] as ColumnDef<BotsElementT>[],
    [t]
  );
};
