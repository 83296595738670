import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SubscribersT, ChangeStatusSubscriberT, CreateSubscriberT, DeleteSubscriberT, OneSubscriberT, UpdateSubscriberT } from "shared/api/types/subscribers";
import { typedInfiniteQuery, typedMutation, typedQuery } from "shared/api/types/typedQueries";
import useToastes from "shared/hooks/useToastes";
import useTypedHistory from "shared/hooks/useTypedHistory";
import { LayoutsE, PathsE, PathsParams } from "shared/routes";
import { formatFilterParams } from 'shared/utils/devUtils';
import { instance } from "./common";

const BASE_URL = 'subscribers'

const SUBSCRIBERS_KEY = 'SUBSCRIBERS_KEY';
const ONE_SUBSCRIBER_KEY = 'ONE_SUBSCRIBER_KEY';

export const useGetSubscribers = typedInfiniteQuery<SubscribersT>(({ params }) => (
  useInfiniteQuery({
    queryKey: [SUBSCRIBERS_KEY, params],
    queryFn: () => instance.get<SubscribersT['response']>(BASE_URL, {
      params: {
        ...params,
        filter: formatFilterParams(params.filter),
      }
    }),
    initialPageParam: 1,
    getNextPageParam: () => null,
    getPreviousPageParam: () => null,
    // getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => null,
    // getPreviousPageParam: (firstPage, allPages, firstPageParam, allPageParams) => null,
  })
));

export const useGetOneSubscriber = typedQuery<OneSubscriberT>(({ pathParams: { subscriberId } }) => (
  useQuery({
    queryKey: [ONE_SUBSCRIBER_KEY, subscriberId],
    queryFn: () => instance.get(`${BASE_URL}/${subscriberId}`),
    enabled: !!subscriberId,
  })
));

export const useCreateSubscriber = typedMutation<CreateSubscriberT>(() => {
  const queryCache = useQueryClient();
  const { successToast } = useToastes();
  const push = useTypedHistory()
  return (
    useMutation({
      mutationFn: (data) => instance.post(BASE_URL, data),
      onSuccess: () => {
        successToast('Subscriber was successfully created');
        push(PathsE.SUBSCRIBERS);
        queryCache.invalidateQueries({ queryKey: [SUBSCRIBERS_KEY] });
      }
    })
  )
});

export const useUpdateOneSubscriber = typedMutation<UpdateSubscriberT>(({ pathParams: { subscriberId } }) => {
  const { successToast } = useToastes();
  const queryCache = useQueryClient()
  const push = useTypedHistory<PathsParams[PathsE.SHOW_SUBSCRIBER]>();
  return (
    useMutation({
      mutationFn: (data) => instance.put(`${BASE_URL}/${subscriberId}`, data),
      onSuccess: () => {
        successToast('Subscriber was successfully updated');
        queryCache.invalidateQueries({ queryKey: [SUBSCRIBERS_KEY] });
        queryCache.invalidateQueries({ queryKey: [ONE_SUBSCRIBER_KEY, subscriberId] })
        push(PathsE.SHOW_SUBSCRIBER, LayoutsE.DASHBOARD, { subscriberId })
      }
    })
  )
});

export const useActivateOneSubscriber = typedMutation<ChangeStatusSubscriberT>(() => {
  const { successToast } = useToastes();
  const queryCache = useQueryClient()
  return (
    useMutation({
      mutationFn: ({ subscriberId }) => instance.put(`${BASE_URL}/${subscriberId}`, { isActive: true }),
      onSuccess: (_, { subscriberId }) => {
        successToast('Subscriber was successfully activated');
        queryCache.invalidateQueries({ queryKey: [SUBSCRIBERS_KEY] });
        queryCache.invalidateQueries({ queryKey: [ONE_SUBSCRIBER_KEY, subscriberId] });
      }
    })
  )
});

export const useDeactivateOneSubscriber = typedMutation<ChangeStatusSubscriberT>(() => {
  const { successToast } = useToastes();
  const queryCache = useQueryClient()
  return (
    useMutation({
      mutationFn: ({ subscriberId }) => instance.put(`${BASE_URL}/${subscriberId}`, { isActive: false }),
      onSuccess: (_, { subscriberId }) => {
        successToast('Subscriber was successfully deactivated');
        queryCache.invalidateQueries({ queryKey: [SUBSCRIBERS_KEY] });
        queryCache.invalidateQueries({ queryKey: [ONE_SUBSCRIBER_KEY, subscriberId] });
      }
    })
  )
});

export const useDeleteSubscriber = typedMutation<DeleteSubscriberT>(({ pathParams: { subscriberId } }) => {
  const { successToast } = useToastes();
  const queryCache = useQueryClient()
  return (
    useMutation({
      mutationFn: () => instance.put(`${BASE_URL}/${subscriberId}`, { isDeleted: true }),
      onSuccess: () => {
        successToast('Subscriber was successfully deleted');
        queryCache.invalidateQueries({ queryKey: [SUBSCRIBERS_KEY] });
        queryCache.invalidateQueries({ queryKey: [ONE_SUBSCRIBER_KEY, subscriberId] });
      }
    })
  )
});
