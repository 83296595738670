import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver } from 'react-hook-form';
import { CreateSubscriberT, OneSubscriberT, UpdateSubscriberT } from 'shared/api/types/subscribers';
import * as Yup from 'yup';
import initDefaultSchema from '../yupConfig';

initDefaultSchema();

// Create Subscriber
export type CreateSubscriberFormT = Pick<CreateSubscriberT['request'], 'name' | 'username' | 'identifier'>;
export const getCreateSubscriberValues = (values?: OneSubscriberT['response']): CreateSubscriberFormT => ({
  identifier: values?.identifier ?? '',
  name: values?.name ?? '',
  username: values?.username ?? '',
});
const createSubscriberSchema: Yup.ObjectSchema<CreateSubscriberFormT> = Yup.object({
  identifier: Yup.string().email().required('Email is a required field'),
  name: Yup.string(),
  username: Yup.string(),
});
export const createSubscriberResolver = yupResolver(createSubscriberSchema) as Resolver<CreateSubscriberFormT>;

// Update Subscriber
export type UpdateSubscriberFormT = Pick<UpdateSubscriberT['request'], 'name' | 'username' | 'identifier'>;
export const getUpdateSubscriberValues = (values?: OneSubscriberT['response']): UpdateSubscriberFormT => ({
  identifier: values?.identifier ?? '',
  name: values?.name ?? '',
  username: values?.username ?? '',
});
const updateSubscriberSchema: Yup.ObjectSchema<UpdateSubscriberFormT> = Yup.object({
  identifier: Yup.string().email().required('Email is a required field'),
  name: Yup.string(),
  username: Yup.string(),
});
export const updateSubscriberResolver = yupResolver(updateSubscriberSchema) as Resolver<UpdateSubscriberFormT>;
