import { InfiniteData, UseInfiniteQueryResult, UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from "axios";
import { GetListParamsT, ListResT } from "./common";

export enum ErrorCodes {
  EXPIRED = "F10020",
  NOT_ENOUGH_TIME = "F10027",
}

export type ErrorsResponse = {
  result: boolean,
  error?: {
    message: string,
    code: string | ErrorCodes,
    data: {
      error: string,
      message: string[],
      statusCode: number,
    } | Record<string, string> | null,
  }
}

export type ErrorRequest = AxiosError<ErrorsResponse>;

type MutationResult<TVariables = unknown, TData = unknown, TError = ErrorRequest> = (
  UseMutationResult<TData, TError, TVariables>
);

type QueryResult<TData = unknown, TError = ErrorRequest> = (
  UseQueryResult<TData, TError>
);

export type InfiniteQueryResult<TData = unknown, TError = ErrorRequest> = (
  UseInfiniteQueryResult<TData, TError>
);

export type CompleteMutationRequestType<Type extends DefaultMutationRequestType> = Type;

export type CompleteQueryRequestType<Type extends DefaultQueryRequestType> = Type;

export type ListQueryRequestType<TData extends UnknownRecord = UnknownRecord, TParams extends UnknownRecord = UnknownRecord> = {
  pathParams?: UnknownRecord | unknown;
  params?: GetListParamsT<TData> & TParams;
  response?: ListResT<TData>
};

export type DefaultQueryRequestType = {
  response?: UnknownRecord | unknown;
  pathParams?: UnknownRecord | unknown;
  params?: UnknownRecord | unknown;
};

export type DefaultMutationRequestType = DefaultQueryRequestType & {
  request: UnknownRecord | unknown;
};

export type QueryOptionsT = {
  enabled: boolean
}

export const typedMutation = <T extends DefaultMutationRequestType>(
  callback: (params?: Pick<T, | 'pathParams' | 'params'>) => MutationResult<T['request'], T['response']>
) => callback;

export const typedQuery = <T extends DefaultQueryRequestType = DefaultQueryRequestType>(
  callback: (params?: Pick<T, | 'pathParams' | 'params'>) => QueryResult<T['response']>
) => callback;

export type InfiniteQueryCallbackT<T extends DefaultQueryRequestType = DefaultQueryRequestType> = (params?: Pick<T, | 'pathParams' | 'params'>, options?: QueryOptionsT) => InfiniteQueryResult<InfiniteData<T['response']>>

export const typedInfiniteQuery = <T extends DefaultQueryRequestType = DefaultQueryRequestType>(
  callback: InfiniteQueryCallbackT<T>
) => callback;
