// chakra imports
import { Box, Flex, Stack } from '@chakra-ui/react';
import Logo from 'components/Logo';
//   Custom components
import Links from 'shared/components/horizon-ui/sidebar/components/Links';
import { RouteT } from 'shared/routes';

// FUNCTIONS

function SidebarContent(props: { routes: RouteT[] }) {
	const { routes } = props;
	// SIDEBAR
	return (
		<Flex direction='column' height='100%' pt='20px' borderRadius='30px'>
			<Logo isLong mb="20px" />
			<Stack direction='column' mb='auto'>
				<Box px="16px">
					<Links routes={routes} />
				</Box>
			</Stack>
		</Flex>
	);
}

export default SidebarContent;
