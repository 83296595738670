import { OneBotT } from 'shared/api/types/bots';
import ModelInfo, { InfoBoxesT } from 'shared/components/ModelInfo';

interface Props {
  data: OneBotT['response'];
}

const BotInfo = ({ data }: Props) => {
  const infoBoxes: InfoBoxesT = [
    {
      label: 'Username',
      type: 'text',
      value: data.username,
    },
    {
      label: 'First name',
      type: 'text',
      value: data.name,
    },
    {
      label: 'Type',
      type: 'text',
      value: data.type,
    },
  ]

  return (
    <ModelInfo infoBoxes={infoBoxes} />
  );
};

export default BotInfo;
