import { Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useForgotPassword } from 'shared/api/requests/auth';
import TextField from 'shared/components/form/TextField';
import ShadowCard from 'shared/components/ShadowCard';
import SubmitButton from 'shared/components/SubmitButton';
import {
  ForgotPasswordFormT, forgotPasswordResolver,
  forgotPasswordValues
} from 'shared/form-values/auth';
import useGetColors from 'shared/hooks/useGetColors';
import { LayoutsE, PathsE } from 'shared/routes';
import { fillFormErrors } from 'shared/utils/devUtils';

const ForgotPassword = () => {
  const { textColor } = useGetColors();
  const { mutate } = useForgotPassword();
  const methods = useForm<ForgotPasswordFormT>({
    defaultValues: forgotPasswordValues,
    resolver: forgotPasswordResolver,
    mode: "onChange"
  });
  useEffect(() => {
    methods.trigger();
  }, []);

  const onSubmit: SubmitHandler<ForgotPasswordFormT> = (values) =>
    mutate(values, { onError: (error) => fillFormErrors(methods.setError, error) });

  return (
    <ShadowCard titleText="Forgot Password?" backUrl={{
      layout: LayoutsE.AUTH,
      path: PathsE.LOGIN
    }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Text color={textColor} fontSize="sm">
            Enter the email address you used when you joined and we&apos;ll send you
            instructions to reset your password.
          </Text>
          <Text color={textColor} fontSize="sm" my="20px">
            For security reasons, we do NOT store your password. So rest assured
            that we will never send your password via email.
          </Text>
          <TextField<ForgotPasswordFormT>
            name="identifier"
            type="email"
            label="Email"
            placeholder="Enter your email"
            mb="24px"
          />
          <SubmitButton label="Send reset instructions" mt="40px" />
        </form>
      </FormProvider>
    </ShadowCard>
  );
};

export default ForgotPassword;
