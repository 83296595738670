import { PathsE, LayoutsE } from "shared/routes";

export default function useGenerateUrl<PathParams extends Record<string, string> = undefined>(
  path: PathsE,
  layout: LayoutsE = LayoutsE.DASHBOARD,
  pathsParams: PathParams
) {
  return `/${layout}/${path}`.replace(/:\w+/g, (match) => {
    const key = match.slice(1);
    return pathsParams[key] !== undefined ? pathsParams[key] : match;
  });
}
