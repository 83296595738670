import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {Locals} from '../shared/utils/constants';
import {en} from './copies/en';
import {ru} from './copies/ru';

const resources = {
  [Locals.EN]: {
    translation: en
  },
  [Locals.RU]: {
    translation: ru
  }
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    resources
  });
