import React from 'react';
import { Box, Image, ImageProps } from '@chakra-ui/react';

interface Props extends ImageProps {
  isLong?: boolean;
}

const Logo = ({ isLong, ...props }: Props) =>
  isLong ? (
    <Box w="100%" {...props}>
      <Image
      margin="auto"
      height="60px"
      width="228px"
      objectFit="cover"
      src={''}
      alt="logo"
    />
    </Box>
  ) : (
    <Image
      boxSize="100px"
      objectFit="cover"
      src={''}
      alt="logo"
      {...props}
    />
  );

export default Logo;
