import { useInfiniteQuery } from '@tanstack/react-query';
import { typedInfiniteQuery } from "shared/api/types/typedQueries";
import { SubscriptionsT } from '../types/subscriptions';
import { instance } from "./common";

const BASE_URL = 'subscriptions'

const SUBSCRIPTIONS_KEY = 'SUBSCRIPTIONS_KEY';

export const useGetSubscriptions = typedInfiniteQuery<SubscriptionsT>(({ params }) => (
  useInfiniteQuery({
    queryKey: [SUBSCRIPTIONS_KEY, params],
    queryFn: () => instance.get<SubscriptionsT['response']>(BASE_URL, { params }),
    initialPageParam: 1,
    getNextPageParam: () => null,
    getPreviousPageParam: () => null,
  })
));
