import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { typedInfiniteQuery, typedMutation, typedQuery } from "shared/api/types/typedQueries";
import useToastes from 'shared/hooks/useToastes';
import useTypedHistory from 'shared/hooks/useTypedHistory';
import { PathsE, PathsParams, LayoutsE } from 'shared/routes';
import { BotsT, ChangeStatusBotT, CreateBotT, DeleteBotT, OneBotT, UpdateBotT } from '../types/bots';
import { instance } from "./common";

const BASE_URL = 'bots'

const BOTS_KEY = 'BOTS_KEY';
const ONE_BOT_KEY = 'ONE_BOT_KEY';

export const useGetBots = typedInfiniteQuery<BotsT>(({ params }) => (
  useInfiniteQuery({
    queryKey: [BOTS_KEY, params],
    queryFn: () => instance.get<unknown, BotsT['response']>(BASE_URL, { params }),
    initialPageParam: 1,
    getNextPageParam: () => null,
    getPreviousPageParam: () => null,
    // getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => null,
    // getPreviousPageParam: (firstPage, allPages, firstPageParam, allPageParams) => null,
  })
));

export const useGetOneBot = typedQuery<OneBotT>(({ pathParams: { botId } }) => (
  useQuery({
    queryKey: [ONE_BOT_KEY, botId],
    queryFn: () => instance.get(`${BASE_URL}/${botId}`),
    enabled: !!botId,
  })
));

export const useCreateBot = typedMutation<CreateBotT>(() => {
  const queryCache = useQueryClient();
  const { successToast } = useToastes();
  const push = useTypedHistory()
  return (
    useMutation({
      mutationFn: (data) => instance.post(BASE_URL, data),
      onSuccess: () => {
        successToast('Bot was successfully created');
        push(PathsE.BOTS);
        queryCache.invalidateQueries({ queryKey: [BOTS_KEY] });
      }
    })
  )
});

export const useUpdateOneBot = typedMutation<UpdateBotT>(({ pathParams: { botId } }) => {
  const { successToast } = useToastes();
  const queryCache = useQueryClient()
  const push = useTypedHistory<PathsParams[PathsE.SHOW_BOT]>();
  return (
    useMutation({
      mutationFn: (data) => instance.put(`${BASE_URL}/${botId}`, data),
      onSuccess: () => {
        successToast('Bot was successfully updated');
        queryCache.invalidateQueries({ queryKey: [BOTS_KEY] });
        queryCache.invalidateQueries({ queryKey: [ONE_BOT_KEY, botId] })
        push(PathsE.SHOW_BOT, LayoutsE.DASHBOARD, { botId })
      }
    })
  )
});

export const useActivateOneBot = typedMutation<ChangeStatusBotT>(() => {
  const { successToast } = useToastes();
  const queryCache = useQueryClient()
  return (
    useMutation({
      mutationFn: ({ botId }) => instance.put(`${BASE_URL}/${botId}`, { isActive: true }),
      onSuccess: (_, { botId }) => {
        successToast('Bot was successfully activated');
        queryCache.invalidateQueries({ queryKey: [BOTS_KEY] });
        queryCache.invalidateQueries({ queryKey: [ONE_BOT_KEY, botId] });
      }
    })
  )
});

export const useDeactivateOneBot = typedMutation<ChangeStatusBotT>(() => {
  const { successToast } = useToastes();
  const queryCache = useQueryClient()
  return (
    useMutation({
      mutationFn: ({ botId }) => instance.put(`${BASE_URL}/${botId}`, { isActive: false }),
      onSuccess: (_, { botId }) => {
        successToast('Bot was successfully deactivated');
        queryCache.invalidateQueries({ queryKey: [BOTS_KEY] });
        queryCache.invalidateQueries({ queryKey: [ONE_BOT_KEY, botId] });
      }
    })
  )
});

export const useDeleteBot = typedMutation<DeleteBotT>(({ pathParams: { botId } }) => {
  const { successToast } = useToastes();
  const queryCache = useQueryClient()
  return (
    useMutation({
      mutationFn: () => instance.put(`${BASE_URL}/${botId}`, { isDeleted: true }),
      onSuccess: () => {
        successToast('Bot was successfully deleted');
        queryCache.invalidateQueries({ queryKey: [BOTS_KEY] });
        queryCache.invalidateQueries({ queryKey: [ONE_BOT_KEY, botId] });
      }
    })
  )
});
