import {
  ErrorMessage as ErrorMessageHookform,
} from '@hookform/error-message';
import {FormErrorMessage } from '@chakra-ui/react';
import { Path, useController } from 'react-hook-form';
import useIsInvalid from './hooks/useIsInvalid';

interface Props {
  name: Path<UnknownRecord>;
}

const ErrorMessage = ({ name }: Props) => {
  const { formState: { errors } } = useController({ name });
  const isInvalid = useIsInvalid(name);
  if (!isInvalid) return null;

  return (
    <FormErrorMessage>
      <ErrorMessageHookform
        errors={errors}
        name={name}
      />
    </FormErrorMessage>
  )
}

export default ErrorMessage;
