import { Box, Button, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateSubscriber } from 'shared/api/requests/subscribers';
import Card from 'shared/components/horizon-ui/card/Card';
import { HSeparator } from 'shared/components/horizon-ui/separator/Separator';
import Link from 'shared/components/Link';
import {
  CreateSubscriberFormT,
  createSubscriberResolver,
  getCreateSubscriberValues
} from 'shared/form-values/subscribers';
import useGetColors from 'shared/hooks/useGetColors';
import { PathsE } from 'shared/routes';
import { fillFormErrors } from 'shared/utils/devUtils';
import PageTitle from '../admin/PageTitle';
import AdminForm from './SubscriberForm';

const Create = () => {
  const { t } = useTranslation();
  const { mutate, isPending } = useCreateSubscriber();
  const { gray800, gray700, white, gray100, brand500, brand400 } = useGetColors();
  const methods = useForm<CreateSubscriberFormT>({
    defaultValues: getCreateSubscriberValues(),
    resolver: createSubscriberResolver,
    mode: "onChange"
  });
  const { formState: { isValid }, trigger } = methods;
  useEffect(() => {
    trigger();
  }, []);

  const onSubmit: SubmitHandler<CreateSubscriberFormT> = (values) => mutate(values, { onError: (error) => fillFormErrors(methods.setError, error) });

  const breadcrumbs = [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
    {
      path: PathsE.SUBSCRIBERS,
      text: t('subscribers.subscribers'),
    },
  ]

  return (
    <Box>
      <PageTitle title={t('subscribers.createSubscriber')} breadcrumbs={breadcrumbs} backUrl={{
        path: PathsE.SUBSCRIBERS
      }} />
      <Card mb="20px" borderRadius="12px">
        <Text color={gray700} fontWeight="600">
          Personal Data
        </Text>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <AdminForm />
            <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
            <Link path={PathsE.SUBSCRIBERS}>
              <Button
                color={gray800}
                bgColor={gray100}
                borderRadius="8px"
                w="100px"
              >
                Cancel
              </Button>
            </Link>
            <Button
              color={white}
              borderRadius="8px"
              _hover={{
                bgColor: brand400,
              }}
              bgColor={brand500}
              ml="20px"
              isLoading={isPending}
              w="100px"
              type="submit"
              isDisabled={!isValid}
            >
              Create
            </Button>
          </form>
        </FormProvider>
      </Card>
    </Box>
  );
};

export default Create;
