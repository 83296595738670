import { Box, Text } from '@chakra-ui/react';
import MatIcon from 'components/MatIcon';
import Link from 'shared/components/Link';
import ShadowCard from 'shared/components/ShadowCard';
import SubmitButton from 'shared/components/SubmitButton';
import useGetColors from 'shared/hooks/useGetColors';
import { LayoutsE, PathsE } from 'shared/routes';

const LinkExpired = () => {
  const { textColor, red500, red50 } = useGetColors();

  return (
    <ShadowCard titleText="Link Expired!">
      <Box
        margin="auto"
        mb="24px"
        borderRadius="50%"
        backgroundColor={red50}
        w="100px"
        h="100px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <MatIcon name="dangerous" color={red500} size={48} />
      </Box>
      <Text color={textColor} fontSize="sm">
        The invitation link has expired. Please request a new link from an
        administrator or log in.
      </Text>
      <Link layout={LayoutsE.AUTH} path={PathsE.LOGIN}>
        <SubmitButton label="Go to login" mt="40px" />
      </Link>
    </ShadowCard>
  );
};

export default LinkExpired;
