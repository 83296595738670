import { OneSubscriberT } from 'shared/api/types/subscribers';
import ModelInfo, { InfoBoxesT } from 'shared/components/ModelInfo';

interface Props {
  data: OneSubscriberT['response'];
}

const AdminInfo = ({ data }: Props) => {
  const infoBoxes: InfoBoxesT = [
    {
      label: 'Email',
      type: 'copy-text',
      value: {
        label: data.identifier,
        valueToCopy: data.identifier,
      },
      style: {
        width: "unset",
      }
    },
    {
      label: 'First name',
      type: 'text',
      value: data.name,
    },
    {
      label: 'Last name',
      type: 'text',
      value: data.username,
    },
  ]

  return (
    <ModelInfo infoBoxes={infoBoxes} />
  );
};

export default AdminInfo;
