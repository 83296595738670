import { useInfiniteQuery } from '@tanstack/react-query';
import { typedInfiniteQuery } from "shared/api/types/typedQueries";
import { ChannelsT } from '../types/channels';
import { instance } from "./common";

const BASE_URL = 'channels'

const CHANNELS_KEY = 'CHANNELS_KEY';

export const useGetChannels = typedInfiniteQuery<ChannelsT>(({ params }) => (
  useInfiniteQuery({
    queryKey: [CHANNELS_KEY, params],
    queryFn: () => instance.get<ChannelsT['response']>(BASE_URL, { params }),
    initialPageParam: 1,
    getNextPageParam: () => null,
    getPreviousPageParam: () => null,
  })
));
