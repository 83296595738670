import * as Yup from 'yup';
import { capitalizeFirst } from './utils/formattings';

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,24}$/;

const initDefaultSchema = () => {
  Yup.setLocale({
    string: {
      email: 'Please enter a valid email address',
    },
    mixed: {
      required: ({ path }) => `${capitalizeFirst(path)} is a required field`,
    },
  });
};

export default initDefaultSchema;
