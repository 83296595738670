import { useInfiniteQuery } from '@tanstack/react-query';
import { typedInfiniteQuery } from "shared/api/types/typedQueries";
import { LinksT } from '../types/links';
import { instance } from "./common";

const BASE_URL = 'links'

const LINKS_KEY = 'LINKS_KEY';

export const useGetLinks = typedInfiniteQuery<LinksT>(({ params }) => (
  useInfiniteQuery({
    queryKey: [LINKS_KEY, params],
    queryFn: () => instance.get<LinksT['response']>(BASE_URL, { params }),
    initialPageParam: 1,
    getNextPageParam: () => null,
    getPreviousPageParam: () => null,
  })
));
