export const ru = {
  "dtos": {
    "link_one": "Ссылка",
    "link_other": "Ссылки",
  },
  "general": {
    "home": "Главная",
    "administrator": "Администратор",
    "administrators": "Администраторы",
    "approveTo": "Вы уверены что хотите {{ phrase }}?",
    "createAdministrator": "Создать Администратора",
    "viewAdministrator": "Просмотр Администратора",
    "editAdministrator": "Изменение Администратора",
    "pageNotFound": "Страница не найдена",
    "personalData": "Персональные данные",
    "filters": "Фильтры",
    "table": {
      "dtoTable": "Таблица {{ dtoName }}",
      "pagination": "Отображается от {{currCountOfRows}} до {{toCountOfRows}} из {{total}} записей.",
      "noData": "Данные отсутствуют"
    }
  },
  "buttons": {
    "apply": "Применить",
    "search": "Поиск",
    "select": "Выбрать",
    "cancel": "Отмена",
    "edit": "Изменить"
  },
  "profile": {
    "hey": "Привет, {{firstName}} {{middleName}} {{lastName}}",
    "logOut": "Выйти"
  },
  "subscribers": {
    "subscribers": "Подписчики",
    "createSubscriber": "Создать подписчика",
    "editSubscriber": "Изменить подписчика",
    "viewSubscriber": "Просмотреть подписчика",
    "subscribersTable": "Таблица подписчиков",
    "table": {
      "id": "ID",
      "identifier": "Идентификатор",
      "name": "Имя",
      "username": "Имя пользователя",
      "bot": "Бот",
      "resources": "Ресурсы",
      "buyer": "Покупатель",
      "source": "Источник",
      "messages": "Сообщения",
      "createdAt": "Создано",
      "updatedAt": "Обновлено",
    }
  },
  "channels": {
    "channels": "Каналы",
    "createChannel": "Создать канал",
    "editChannel": "Изменить канал",
    "viewChannel": "Просмотреть канал",
    "channelsTable": "Таблица каналов",
    "table": {
      "id": "ID",
      "identifier": "Идентификатор",
      "name": "Имя",
      "createdAt": "Создано",
      "updatedAt": "Обновлено",
    }
  },
  "subscriptions": {
    "subscriptions": "Подписки",
    "createSubscription": "Создать подписку",
    "editSubscription": "Изменить подписку",
    "viewSubscription": "Просмотреть подписку",
    "subscriptionsTable": "Таблица подписок",
    "table": {
      "id": "ID",
      "identifier": "Идентификатор",
      "username": "Имя пользователя",
      "url": "Url",
      "name": "Имя",
      "createdAt": "Создано",
      "updatedAt": "Обновлено",
      "buyer": "Покупатель",
      "source": "Источник",
    }
  },
  "bots": {
    "bots": "Боты",
    "createBot": "Создать бота",
    "editBot": "Изменить бота",
    "viewBot": "Просмотреть бота",
    "deleteBot": "Удалить бота",
    "botsTable": "Таблица ботов",
    "table": {
      "id": "ID",
      "identifier": "Идентификатор",
      "name": "Имя",
      "createdAt": "Создано",
      "updatedAt": "Обновлено",
      "username": "Имя пользователя",
      "type": "Тип",
    }
  },
  "links": {
    "support": "Поддержка",
    "contactDeveloper": "Связи с разработчиком"
  },
  "footer": {
    "allRightsReserved": "Все права защищены"
  },
  "fields": {
    "channel_one": "Канал",
    "channel_other": "Каналы"
  }
};
