import { dateFilterKeys } from "./constants";

const dateFormatterToState = new Intl.DateTimeFormat("zh-CN", {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hourCycle: "h23"
});

const isValidDate = (dateObject: Date) => new Date(dateObject).toString() !== "Invalid Date";

// this test text -> This test text
export const capitalizeFirst = (str: string) => (
  str.charAt(0).toUpperCase() + str.slice(1)
);

// 179 -> 2 min 59 sec
export const formatSecsToMinsAndSecs = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime =
    (minutes ? `${minutes} min` : '') +
    (minutes && remainingSeconds ? ' ' : '') +
    (remainingSeconds ? `${remainingSeconds} sec` : '');

  return formattedTime;
}

// camelCaseToTitle => camel Case To Title
export const camelCaseToTitle = (str: string) => {
  const result = str.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}


// Format date from "2023-12-06T10:40:10.706Z" to "01.11.2023, 11:33" 
export const formatDateToHuman = (inputDate: string, isShowTime: boolean = true) => {
  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  if (isShowTime) return `${day}.${month}.${year}, ${hours}:${minutes}`;
  return `${day}.${month}.${year}`;
}

// Format Date object to "01.11.2023, 11:33" 
export const formatDateToString = (
  dateOrStr: Date | null = new Date(),
): string | null => {
  if (!dateOrStr) return null;
  const date = new Date(dateOrStr)
  if (!isValidDate(date)) return null;
  return dateFormatterToState.format(date);
};

// Format date string "01.11.2023, 11:33" object to Date
export const formatStringToDate = (str: string | null): Date | null => {
  if (typeof str !== "string") return str;
  const date = new Date(`20${str}`);
  if (!isValidDate(date)) return null;
  return date;
};

// Format timestamp "01.11.2023, 11:33" object to Date
export const formatTimestampToDate = (timestamp: number | null): Date | null => {
  if (!timestamp) return null;
  const date = new Date(timestamp);
  if (!isValidDate(date)) return null;
  return date;
};

// Format Date object to timestamp
export const formatDateToTimestamp = (date: Date | null): number => {
  if (!date) return null;
  return date.getTime();
};

// Format timestamp to back format with time
// 1720534823109 to '2024-07-09 17:20:23'
export const formatTimestampToBackFormat = (timestamp: number | null, showTime?: boolean): string => {
  const date = new Date(timestamp);
  if (showTime) {
    return date.toLocaleDateString("sv-SE", { hour: 'numeric', minute: 'numeric', second: 'numeric' })
  }
  return date.toLocaleDateString("sv-SE")
};

export const tableSearchFormatter = <TData extends UnknownRecord>(values: TData): TData => (
  Object.entries(values).reduce(
    (prev, [key, curr]) => {
      if (dateFilterKeys.includes(key)) {
        const [firstData, secondData] = curr as [number | null, number | null];
        return {
          ...prev,
          [`${key}Start`]: formatTimestampToBackFormat(firstData),
          [`${key}End`]: formatTimestampToBackFormat(secondData),
        };
      };
      return ({ ...prev, [key]: curr });
    },
    {} as TData
  )
);
