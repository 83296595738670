import { Box, Heading } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
import { useActivateOneSubscriber, useDeactivateOneSubscriber, useGetSubscribers } from 'shared/api/requests/subscribers';
import { SubscribersT } from 'shared/api/types/subscribers';
import Card from 'shared/components/horizon-ui/card/Card';
import InfiniteTable from 'shared/components/table/InfiniteTable';
import useGetColors from 'shared/hooks/useGetColors';
import PageTitle from '../admin/PageTitle';
import { useGetColumns, useGetFilterFields, useGetGetBreadcrumbs } from './constants';

const Index = () => {
  const { t } = useTranslation();
  const { blue800 } = useGetColors();
  const { mutate: activateMutation } = useActivateOneSubscriber();
  const { mutate: deactivateMutation } = useDeactivateOneSubscriber();
  const handleActivate = (subscriberId: string) => activateMutation({ subscriberId });
  const handleDeactivate = (subscriberId: string) => deactivateMutation({ subscriberId });

  const columns = useGetColumns();
  const breadcrumbs = useGetGetBreadcrumbs();
  const filterFields = useGetFilterFields();

  return (
    <Box>
      <PageTitle title={t('subscribers.subscribers')} breadcrumbs={breadcrumbs} />
      <Card mb="20px" borderRadius="12px">
        <Heading size="md" marginBottom={5} color={blue800}>
          {t('subscribers.subscribersTable')}
        </Heading>
        <InfiniteTable<SubscribersT>
          query={useGetSubscribers}
          columns={columns}
          filterFields={filterFields}
          clickable
        />
      </Card>
    </Box>
  );
};

export default Index;
