import { useMutation, useQuery } from '@tanstack/react-query';
import { OnePermissionT, PermissionsSelfT, PermissionsT, UpdatePermissionT } from "shared/api/types/permissions";
import { typedMutation, typedQuery } from "shared/api/types/typedQueries";
import { instance } from "./common";

const BASE_URL = 'permissions'

export const SELF_PERMISSIONS_KEY = 'SELF_PERMISSIONS_KEY';
export const PERMISSIONS_KEY = 'PERMISSIONS_KEY';
export const ONE_PERMISSION_KEY = 'ONE_PERMISSION_KEY';

export const useGetPermissions = typedQuery<PermissionsT>(({ params }) => (
  useQuery({
    queryKey: [PERMISSIONS_KEY, params],
    queryFn: () => instance.get(BASE_URL, { params })
  })
));

export const useGetPermissionsSelf = typedQuery<PermissionsSelfT>(() => (
  useQuery({
    queryKey: [SELF_PERMISSIONS_KEY],
    queryFn: () => instance.get(`${BASE_URL}/self`)
  })
));

export const useGetOnePermission = typedQuery<OnePermissionT>(({ pathParams: { id } }) => (
  useQuery({
    queryKey: [ONE_PERMISSION_KEY],
    queryFn: () => instance.get(`${BASE_URL}/${id}`),
    enabled: !!id,
  })
));

export const useUpdateOnePermission = typedMutation<UpdatePermissionT>(({ pathParams: { id } }) => (
  useMutation({
    mutationFn: (data) => instance.put(`${BASE_URL}/${id}`, data)
  })
));
