import { useEffect, useState } from 'react'

export function useDebounce<T>(value: T, delay: number = 500, callBackFormatter?: (value: T) => UnknownRecord): T {
  const [debouncedValue, setDebouncedValue] = useState<T | UnknownRecord>(value)

  useEffect(() => {
    if (callBackFormatter) setDebouncedValue(callBackFormatter(value))
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (callBackFormatter) setDebouncedValue(callBackFormatter(value))
      else setDebouncedValue(value);
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue as T;
}
