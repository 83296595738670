import { Flex, Th, Thead, Tr } from '@chakra-ui/react';
import { chakra } from '@chakra-ui/system';
import { Column, Table, flexRender } from '@tanstack/react-table';
import MatIcon from 'components/MatIcon';
import { useCallback } from 'react';
import { OrderT } from 'shared/api/types/common';
import useGetColors from 'shared/hooks/useGetColors';

interface Props<TData> {
  table: Table<TData>;
  order?: OrderT | null;
  sortBy?: keyof TData | null;
  setOrder?: (value: OrderT | null) => void;
  setSortBy?: (value: keyof TData | null) => void;
  disabledSort?: boolean;
}

const TableHead = <TData extends UnknownRecord>({
  table,
  order,
  sortBy,
  disabledSort,
  setOrder,
  setSortBy,
}: Props<TData>) => {
  type ColumnT = Column<TData>;
  const { white, gray300, gray600, blue800 } = useGetColors();

  const handleSortByHeader = (column: ColumnT) => {
    if (!setSortBy || !setOrder) return;
    if (sortBy === column.columnDef.id && order === 'DESC') {
      setSortBy(null);
      setOrder(null);
      return;
    }
    if (sortBy === column.columnDef.id && order === 'ASC') {
      setOrder('DESC');
      return;
    }
    setSortBy(column.columnDef.id);
    setOrder('ASC');
  };

  const findActiveColumn = useCallback(
    (column: ColumnT) => column.columnDef.id === sortBy,
    [sortBy]
  );

  return (
    <Thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <Tr
          key={headerGroup.id}
          bg={white}
          borderBottom={`2px solid ${gray300}`}
        >
          {headerGroup.headers.map((header) => {
            const enabledToSort = header.column.columnDef.meta?.isSorting !== false && !disabledSort;
            return (
              <Th
                py="10px"
                px="5px"
                key={header.id}
                onClick={() => enabledToSort ? handleSortByHeader(header.column) : null}
                isNumeric={(header.column.columnDef.meta)?.isNumeric}
                color={findActiveColumn(header.column) && enabledToSort ? blue800 : gray600}
                cursor={enabledToSort ? "pointer" : 'auto'}
                {...header.column.columnDef?.meta?.styles?.sizes}
              >
                <Flex alignItems="center" position="relative" width="max-content">
                {flexRender(header.column.columnDef.header, header.getContext())}
                {enabledToSort && (
                  <chakra.span position="absolute" right="-20px">
                    {findActiveColumn(header.column) ? (
                      order === 'DESC' ? (
                        <MatIcon name="arrow_downward_alt" variant="rounded" />
                      ) : (
                        <MatIcon name="arrow_upward_alt" variant="rounded" />
                      )
                    ) : null}
                  </chakra.span>
                )}
                </Flex>
              </Th>
            )
          })}
        </Tr>
      ))}
      {/* {table.getHeaderGroups().map((headerGroup) => {
        const doesGroupHaveSort = headerGroup.headers.some((header) => !!header.column.columnDef.meta?.sortingUtils)
        if (!doesGroupHaveSort) return null;
        return (
          <Tr
            key={headerGroup.id}
          >
            {headerGroup.headers.map((header) => (
              <Th
                px="5px"
                py="10px"
                {...header.column.columnDef?.meta?.styles?.sizes}
                key={header.id}
              >
                <TableSearchField columnDef={header.column.columnDef} />
              </Th>
            ))}
          </Tr>
        )
      })} */}
    </Thead>
  );
};

export default TableHead;
