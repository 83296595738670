
import MatIcon, { IconSizeT, IconVariantT } from 'components/MatIcon';
import { useTranslation } from 'react-i18next';

// Subscribers
import Subscribers from './views/subscribers/Index';
import CreateSubscriber from './views/subscribers/Create';
import UpdateSubscriber from './views/subscribers/Update';
import ShowSubscriber from './views/subscribers/Show';

// Bots
import Bots from './views/bots/Index';
import CreateBot from './views/bots/Create';
import UpdateBot from './views/bots/Update';
import ShowBot from './views/bots/Show';

// Channels
import Channels from './views/channels/Index';

// Subscriptions
import Subscriptions from './views/subscriptions/Index';

// Links
import Links from './views/links/Index';

// Auth Imports
import Login from './views/auth/Login';
import SetPassword from './views/auth/SetPassword';
import TwoFactor from './views/auth/TwoFactor';
import NotFound from './views/system/NotFound';
import ForgotPassword from './views/auth/ForgotPassword';
import LinkExpired from './views/auth/LinkExpired';

export type PathsParams = {
  [PathsE.UPDATE_SUBSCRIBER]: {
    subscriberId: string
  },
  [PathsE.SHOW_SUBSCRIBER]: {
    subscriberId: string
  },
  [PathsE.UPDATE_BOT]: {
    botId: string
  },
  [PathsE.SHOW_BOT]: {
    botId: string
  },
}

export enum PathsE {
  // HOME
  HOME = 'home',

  // AUTH
  LOGIN = 'login',
  TWO_FA = 'two-factor',
  SET_PASSWORD = 'set-password',
  FORGOT_PASSWORD = 'forgot-password',

  // SUBSCRIBERS
  SUBSCRIBERS = 'subscribers',
  SHOW_SUBSCRIBER = 'subscribers/:subscriberId',
  CREATE_SUBSCRIBER = 'subscribers/new',
  UPDATE_SUBSCRIBER = 'subscribers/:subscriberId/edit',

  // CHANNELS
  CHANNELS = 'channels',
  SHOW_CHANNEL = 'channels/:channelId',
  CREATE_CHANNEL = 'channels/new',
  UPDATE_CHANNEL = 'channels/:channelId/edit',

  // SUBSCRIPTIONS
  SUBSCRIPTIONS = 'subscriptions',
  SHOW_SUBSCRIPTION = 'subscriptions/:subscriptionId',
  CREATE_SUBSCRIPTION = 'subscriptions/new',
  UPDATE_SUBSCRIPTION = 'subscriptions/:subscriptionId/edit',

  // BOTS
  BOTS = 'bots',
  SHOW_BOT = 'bots/:botId',
  CREATE_BOT = 'bots/new',
  UPDATE_BOT = 'bots/:botId/edit',

  // LINKS
  LINKS = 'links',

  // HIDDEN
  DEFAULT = 'default',
  NFT = 'nft-marketplace',
  DATA_TABLES = 'data-tables',
  PROFILE = 'profile',

  // SYSTEM
  NOT_FOUND = '404',
  LINK_EXPIRED = 'expired',
}

export enum LayoutsE {
  AUTH = 'auth',
  DASHBOARD = 'dashboard',
  SYSTEM = 'system',
}

export interface RouteT {
  name?: string
  isDisabled: boolean
  isShowInNav: boolean
  path: PathsE
  component: () => JSX.Element
  icon?: JSX.Element
  secondary?: boolean
  layout?: LayoutsE
}

export type LayoutT = {
  [key in LayoutsE]: {
    isProtected: boolean;
    isHideInAuth: boolean;
    routes: RouteT[];
  };
}

const iconStyles = { size: 24 as IconSizeT, fill: true, variant: "outlined" as IconVariantT, color: "inherit" };

const useRoutes = () => {
  const {t} = useTranslation();

  const routes: LayoutT = {
    [LayoutsE.AUTH]: {
      isHideInAuth: true,
      isProtected: false,
      routes: [
        {
          path: PathsE.LOGIN,
          component: Login,
          isDisabled: false,
          isShowInNav: false,
        },
        {
          path: PathsE.TWO_FA,
          component: TwoFactor,
          isDisabled: false,
          isShowInNav: false,
        },
        {
          path: PathsE.SET_PASSWORD,
          component: SetPassword,
          isDisabled: false,
          isShowInNav: false,
        },
        {
          path: PathsE.FORGOT_PASSWORD,
          component: ForgotPassword,
          isDisabled: false,
          isShowInNav: false,
        },
      ],
    },
    [LayoutsE.DASHBOARD]: {
      isHideInAuth: false,
      isProtected: true,
      routes: [
        // SUBSCRIBERS
        {
          name: t('subscribers.subscribers'),
          path: PathsE.SUBSCRIBERS,
          icon: <MatIcon name="admin_panel_settings" {...iconStyles} />,
          component: Subscribers,
          isDisabled: false,
          isShowInNav: true,
        },
        {
          name: t('subscribers.createSubscriber'),
          path: PathsE.CREATE_SUBSCRIBER,
          component: CreateSubscriber,
          isDisabled: false,
          isShowInNav: false,
        },
        {
          name: t('general.viewSubscriber'),
          path: PathsE.SHOW_SUBSCRIBER,
          component: ShowSubscriber,
          isDisabled: false,
          isShowInNav: false,
        },
        {
          name: t('subscribers.editSubscriber'),
          path: PathsE.UPDATE_SUBSCRIBER,
          component: UpdateSubscriber,
          isDisabled: false,
          isShowInNav: false,
        },
        // CHANNELS
        {
          name: t('channels.channels'),
          path: PathsE.CHANNELS,
          icon: <MatIcon name="group" {...iconStyles} />,
          component: Channels,
          isDisabled: false,
          isShowInNav: true,
        },
        // SUBSCRIPTIONS
        {
          name: t('subscriptions.subscriptions'),
          path: PathsE.SUBSCRIPTIONS,
          icon: <MatIcon name="subscriptions" {...iconStyles} />,
          component: Subscriptions,
          isDisabled: false,
          isShowInNav: true,
        },
        // BOTS
        {
          name: t('bots.bots'),
          path: PathsE.BOTS,
          icon: <MatIcon name="smart_toy" {...iconStyles} />,
          component: Bots,
          isDisabled: false,
          isShowInNav: true,
        },
        {
          name: t('bots.createBot'),
          path: PathsE.CREATE_BOT,
          component: CreateBot,
          isDisabled: false,
          isShowInNav: false,
        },
        {
          name: t('bots.viewBot'),
          path: PathsE.SHOW_BOT,
          component: ShowBot,
          isDisabled: false,
          isShowInNav: false,
        },
        {
          name: t('bots.editBot'),
          path: PathsE.UPDATE_BOT,
          component: UpdateBot,
          isDisabled: false,
          isShowInNav: false,
        },
        // LINKS
        {
          name: t('dtos.link_other'),
          path: PathsE.LINKS,
          icon: <MatIcon name="link" {...iconStyles} />,
          component: Links,
          isDisabled: false,
          isShowInNav: true,
        },
      ],
    },
    [LayoutsE.SYSTEM]: {
      isHideInAuth: false,
      isProtected: false,
      routes: [
        {
          name: t('general.pageNotFound'),
          path: PathsE.NOT_FOUND,
          component: NotFound,
          isDisabled: false,
          isShowInNav: false,
        },
        {
          path: PathsE.LINK_EXPIRED,
          component: LinkExpired,
          isDisabled: false,
          isShowInNav: false,
        },
      ],
    },
  };

  return routes;
}

export default useRoutes;
