import { CloseButton, Flex } from '@chakra-ui/react';
import React from 'react';
import { LayoutsE, PathsE } from 'shared/routes';
import Title from './Title';
import Card from './horizon-ui/card/Card';
import Link from './Link';

interface Props {
  children: React.ReactNode;
  titleText: string,
  backUrl?: {
    layout: LayoutsE,
    path: PathsE,
  },
}

const ShadowCard = ({ children, titleText, backUrl }: Props) => {
  return (
    <Card
      boxShadow={'0px 18px 40px 0px rgba(112, 144, 176, 0.12);'}
      margin="auto"
      width="auto"
      padding="40px"
    >
      {backUrl && <Link {...backUrl}><CloseButton position="absolute" right="10px" top="10px" /></Link>}
      <Title text={titleText} />
      <Flex
        direction="column"
        w={{ base: '100%', md: '400px' }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: 'auto', lg: 'unset' }}
        me="auto"
        mb={{ base: '20px', md: 'auto' }}
      >
        {children}
      </Flex>
    </Card>
  );
};

export default ShadowCard;
